import React from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRoute from '@vl/hooks/useGbRoute';
import { notification } from 'antd';
import { hook, bindings } from '@vl/redata';

export default bindings({
  formContainer: {
    rules: [
      [
        'data',
        {
          data: {
            registerEmail: hook(() => {
              const [isEmailRegister, $isEmailRegister] = React.useState(false);
              return {
                isEmailRegister,
                toggleEmailRegister: () => $isEmailRegister(!isEmailRegister),
              };
            }),
            form: hook((ctx) => {
              const route = useRoute();

              const form = useFormik({
                initialValues: {
                  name: '',
                  username: '',
                  password: '',
                  passwordConfirm: '',
                  isNewUser: false,
                },
                onSubmit: async (values) => {
                  try {
                    const user = await ctx
                      .get('authModel')
                      .auth()
                      .createUserWithEmailAndPassword(values.username, values.password);
                    await ctx
                      .get('authModel')
                      .auth()
                      .currentUser.updateProfile({
                        displayName: values.name,
                      });
                    const isNewUser = _.get(user, 'additionalUserInfo.isNewUser');
                    form.setFieldValue('isNewUser', isNewUser);
                    if (!!isNewUser) {
                      notification['success']({
                        message: ctx.apply('i18n.t', 'Signup.signupNotification'),
                        placement: 'bottomRight',
                      });
                      route.navigateExternal(route.toLocale('/'));
                    }
                  } catch (err) {
                    if (['auth/email-already-in-use'].includes(err.code)) {
                      form.setFieldError('username', `${err.message}`);
                    } else {
                      form.setFieldError('password', `${err.message}`);
                    }
                  }
                },
                validationSchema: Yup.object().shape({
                  username: Yup.string()
                    .email('Invalid email')
                    .required(ctx.apply('i18n.t', 'Form.required')),
                  password: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
                }),
              });

              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
            canRegisterEmail: hook((ctx) => {
              return ctx.get('form.dirty') && !ctx.get('form.isSubmitting');
            }),
          },
        },
      ],
    ],
    children: {
      emailRegisterForm: {
        rules: [
          [
            'display',
            {
              display: hook((ctx) => !!ctx.get('registerEmail.isEmailRegister')),
            },
          ],
        ],
      },
      oauthRegisterForm: {
        rules: [
          [
            'display',
            {
              display: hook((ctx) => !ctx.get('registerEmail.isEmailRegister')),
            },
          ],
        ],
      },
    },
  },
});
